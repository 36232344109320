import React from 'react';

const aboutItem3 = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9199 8.81125C22.3716 8.08538 24.1137 8.08538 25.7106 8.81125L50.6804 19.9896C52.1322 20.5703 52.858 22.3124 52.1322 23.7641C51.8514 24.4507 51.3373 25.0163 50.6804 25.361L34.5662 32.6197C33.1144 33.3456 31.3724 33.3456 29.7755 32.6197L4.66045 21.4413C3.20871 20.8606 2.48284 19.1186 3.20871 17.6668C3.49906 16.941 4.08902 16.3603 4.66971 16.2151L20.9199 8.81125Z" fill="url(#paint0_linear_52_47)" />
      <path d="M51.8535 38.038C52.5775 36.7349 51.8535 34.9973 50.4055 34.4181L42.0505 30.4311L35.0974 33.537C32.5676 34.5723 31.295 34.6149 28.7395 33.5074L13.5668 26.5278L4.64988 30.5086C4.29061 30.6528 3.96426 30.8683 3.69052 31.142C3.41678 31.4158 3.20132 31.7421 3.05712 32.1014C2.47793 33.4045 3.20192 35.1421 4.64988 35.7213L29.5549 46.8706C31.1476 47.5946 32.8852 47.5946 34.3331 46.8706L50.4055 39.6308C51.0607 39.2869 51.5734 38.7229 51.8535 38.038Z" fill="#0346F1" />
      <defs>
        <linearGradient id="paint0_linear_52_47" x1="-2.69588" y1="0.374536" x2="13.0498" y2="56.9649" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default aboutItem3;
