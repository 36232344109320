import React from 'react';

const logoSmall = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6473 7.71757L14.212 0.423462L17.0355 2.07052L4.98847 9.03521C3.90612 9.64698 3.24729 10.8234 3.24729 12.094V22.8705L5.12964 23.9528V12.5176C5.12964 11.6705 5.55317 10.9176 6.30611 10.494L18.9649 3.15287L26.5414 7.52933L14.4473 14.5411C13.3649 15.1529 12.7061 16.3293 12.7061 17.5999V31.294L9.83552 29.5999V15.247C9.83552 14.3999 10.259 13.647 11.012 13.2234L21.2237 7.3411L19.3414 6.25875L9.7414 11.8117C8.65905 12.4234 8.00023 13.5999 8.00023 14.8705V28.5646L0.376709 24.1411V9.88227C0.376709 8.98816 0.847297 8.1411 1.6473 7.71757Z" fill="#004CC7" />
      <path d="M13.0823 31.9529L9.45881 29.8352V15.247C9.45881 14.2588 9.97646 13.3647 10.8235 12.8941L20.4706 7.34117L19.3411 6.68234L9.9294 12.1412C8.98822 12.7059 8.37646 13.7412 8.37646 14.8235V29.1764L0 24.3764V9.88234C0 8.84704 0.564705 7.90587 1.45882 7.38822L14.2117 0L17.8353 2.07059L5.17646 9.36469C4.23529 9.9294 3.62352 10.9647 3.62352 12.047V22.6353L4.75293 23.2941V12.5176C4.75293 11.5294 5.27058 10.6353 6.11764 10.1647L18.9647 2.72941L27.2941 7.5294L14.6353 14.8706C13.6941 15.4353 13.0823 16.4706 13.0823 17.5529V31.9529ZM10.2588 29.3647L12.3294 30.5882V17.6C12.3294 16.2353 13.0823 14.9176 14.2588 14.2117L25.7882 7.5294L18.9647 3.62352L6.44705 10.8235C5.83529 11.1529 5.45882 11.8117 5.45882 12.5176V24.6588L2.82353 23.1058V12.0941C2.82353 10.7294 3.57647 9.41175 4.75293 8.70587L16.2823 2.07059L14.2117 0.894116L1.83529 8.04705C1.17647 8.42352 0.799999 9.1294 0.799999 9.88234V23.9058L7.62352 27.8588V14.8235C7.62352 13.4588 8.37646 12.1412 9.55293 11.4353L19.3411 5.74117L21.9764 7.29411L11.2 13.5529C10.5882 13.8823 10.2118 14.5412 10.2118 15.247L10.2588 29.3647Z" fill="#004CC7" />
      <path d="M14.1177 32V17.9765C14.1177 16.9883 14.6353 16.0942 15.4824 15.5765L27.7647 8.5177V12.753L19.5765 17.5059C18.4941 18.1177 17.8353 19.2942 17.8353 20.5177V25.6941L23.3883 22.4942C24 22.1647 24.3294 21.5059 24.3294 20.8471V16.5647L27.6706 14.6353V22.6824C27.6706 23.6706 27.153 24.6118 26.2588 25.0824L14.1177 32ZM15.9059 16.2824C15.2941 16.6118 14.9177 17.2706 14.9177 17.9765V30.5883L25.9294 24.3294C26.5412 24 26.9177 23.3412 26.9177 22.6353V16L25.2235 16.9883V20.8C25.2235 21.7883 24.7059 22.6824 23.8588 23.2L17.0353 27.1059V20.4706C17.0353 18.9177 17.8824 17.5059 19.2 16.7059L27.0118 12.1883V9.83535L15.9059 16.2824Z" fill="#004CC7" />
    </svg>
  );
};

export default logoSmall;
