import React from 'react';

const aboutItem1 = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_395_323)">
        <path fillRule="evenodd" clipRule="evenodd" d="M46.9167 30.2501C46.9167 42.6535 36.8617 52.7084 24.4583 52.7084C12.0549 52.7084 2 42.6535 2 30.2501C2 17.8467 12.0549 7.79175 24.4583 7.79175C36.8617 7.79175 46.9167 17.8467 46.9167 30.2501ZM38.575 30.2501C38.575 38.0465 32.2548 44.3668 24.4583 44.3668C16.6619 44.3668 10.3417 38.0465 10.3417 30.2501C10.3417 22.4537 16.6619 16.1334 24.4583 16.1334C32.2548 16.1334 38.575 22.4537 38.575 30.2501Z" fill="url(#paint0_linear_395_323)" />
        <path d="M53.5715 8.28574C53.5715 11.7572 50.7573 14.5715 47.2858 14.5715C43.8143 14.5715 41.0001 11.7572 41.0001 8.28574C41.0001 4.81424 43.8143 2.00003 47.2858 2.00003C50.7573 2.00003 53.5715 4.81424 53.5715 8.28574Z" fill="#0346F1" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_395_323" x1="-4.24514" y1="-13.6901" x2="45.553" y2="84.528" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_395_323">
          <rect width="55" height="55" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default aboutItem1;
