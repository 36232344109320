import React, { createContext, useCallback, useState } from 'react';

import { IModal, IModalContext, ModalConfig } from './ModalContext.types';

const MODAL_DELAYED = 400

const ModalContext = createContext<IModalContext>({
  modals: [],
  closedModals: [],
  openModal: (config: ModalConfig) => { },
  closeModal: (type: string) => { },
});

ModalContext.displayName = 'ModalContext';

const ModalProvider = (props: any) => {
  const [modals, setModals] = useState<IModal[]>([]);
  const [closedModals, setClosedModals] = useState<string[]>([]);

  const openModal = useCallback((config: ModalConfig) => {
    const newModal = { config };

    setModals(prev => [...prev, newModal]);
  }, []);

  const closeModal = useCallback((type: string) => {
    setClosedModals(prev => [...prev, type]);

    setTimeout(() => {
      setModals(prev => prev.filter(modal => modal.config.type !== type));
      setClosedModals(prev => prev.filter(modalType => modalType !== type));
    }, MODAL_DELAYED);
  }, []);

  return (
    <ModalContext.Provider
      {...props}
      value={{
        modals,
        openModal,
        closeModal,
        closedModals,
      }}
    />
  );
};

export { ModalProvider, ModalContext };