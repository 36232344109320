import React from 'react';

const logoFooter = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 151 45" fill="none" className="logo-btn">
      <path className="logo-btn__text" d="M59.4577 30.7526C58.1486 30.7526 56.9679 30.5247 55.9157 30.069C54.8717 29.6133 54.01 28.9629 53.3306 28.1178C52.6595 27.2644 52.2328 26.2536 52.0505 25.0853L55.1576 24.6131C55.4227 25.6736 55.9654 26.4939 56.7857 27.0739C57.6142 27.6538 58.567 27.9438 59.6441 27.9438C60.2821 27.9438 60.8828 27.8444 61.4462 27.6455C62.0096 27.4467 62.4653 27.1567 62.8133 26.7756C63.1696 26.3944 63.3477 25.9263 63.3477 25.3712C63.3477 25.1226 63.3063 24.8948 63.2235 24.6876C63.1406 24.4722 63.0163 24.2817 62.8506 24.116C62.6932 23.9502 62.486 23.8011 62.2292 23.6685C61.9806 23.5277 61.6906 23.4075 61.3592 23.3081L56.736 21.941C56.3383 21.825 55.9074 21.6717 55.4434 21.4812C54.9877 21.2823 54.5527 21.0131 54.1385 20.6734C53.7325 20.3254 53.3969 19.8862 53.1318 19.356C52.8749 18.8174 52.7465 18.1546 52.7465 17.3675C52.7465 16.2158 53.0365 15.2505 53.6165 14.4717C54.2048 13.6846 54.9919 13.0963 55.9778 12.7069C56.9721 12.3175 58.0741 12.1269 59.2837 12.1352C60.51 12.1435 61.6036 12.3548 62.5648 12.769C63.5259 13.175 64.3296 13.7674 64.9758 14.5463C65.6221 15.3251 66.0778 16.2655 66.3429 17.3675L63.124 17.9267C62.9915 17.297 62.7346 16.7626 62.3535 16.3235C61.9806 15.8761 61.5208 15.5364 60.974 15.3044C60.4354 15.0724 59.8596 14.9481 59.2464 14.9315C58.6499 14.9233 58.0948 15.0144 57.5811 15.205C57.0757 15.3872 56.6655 15.6524 56.3507 16.0004C56.0441 16.3484 55.8908 16.7543 55.8908 17.2183C55.8908 17.6575 56.0234 18.0179 56.2885 18.2996C56.5537 18.573 56.8809 18.7926 57.2704 18.9583C57.6681 19.1157 58.0699 19.2483 58.4759 19.356L61.6824 20.2508C62.1215 20.3668 62.6145 20.5242 63.1613 20.7231C63.7081 20.9219 64.2343 21.1995 64.7397 21.5558C65.2451 21.912 65.6594 22.3802 65.9825 22.9601C66.3139 23.5401 66.4796 24.2775 66.4796 25.1723C66.4796 26.1003 66.2849 26.9164 65.8955 27.6207C65.5144 28.3167 64.9965 28.8966 64.342 29.3606C63.6874 29.8246 62.9376 30.1726 62.0925 30.4046C61.2557 30.6366 60.3774 30.7526 59.4577 30.7526Z" fill="#9F9F9F" />
      <path className="logo-btn__text" d="M68.9212 22.5997V20.2508H76.3781V22.5997H68.9212Z" fill="#9F9F9F" />
      <path className="logo-btn__text" d="M86.9898 30.7526C85.8298 30.7526 84.7403 30.5496 83.7212 30.1436C82.7103 29.7293 81.8197 29.1245 81.0491 28.3291C80.2869 27.5337 79.6903 26.5602 79.2595 25.4085C78.8286 24.2485 78.6132 22.9228 78.6132 21.4315C78.6132 19.4761 78.9778 17.8066 79.7069 16.4229C80.436 15.031 81.4344 13.9663 82.7021 13.2289C83.9697 12.4832 85.399 12.1104 86.9898 12.1104C89.1937 12.1104 90.9378 12.624 92.222 13.6514C93.5146 14.6706 94.3887 16.1039 94.8444 17.9516L91.787 18.4363C91.4473 17.3757 90.8922 16.5306 90.1217 15.9009C89.3511 15.263 88.3693 14.944 87.1762 14.944C85.9748 14.9274 84.9764 15.1884 84.181 15.7269C83.3856 16.2655 82.7849 17.0236 82.3789 18.0013C81.9812 18.979 81.7824 20.1224 81.7824 21.4315C81.7824 22.7406 81.9812 23.8798 82.3789 24.8492C82.7766 25.8103 83.3732 26.5602 84.1686 27.0987C84.9723 27.6373 85.9748 27.9148 87.1762 27.9314C88.0793 27.9397 88.8706 27.7781 89.55 27.4467C90.2294 27.107 90.7845 26.5974 91.2153 25.918C91.6462 25.2303 91.9362 24.3728 92.0853 23.3454H88.9286V20.9965H95.2669C95.2835 21.1291 95.2959 21.3238 95.3042 21.5806C95.3125 21.8375 95.3166 21.9907 95.3166 22.0405C95.3166 23.7307 94.9811 25.2345 94.31 26.5519C93.6471 27.861 92.6943 28.8884 91.4515 29.6341C90.2087 30.3797 88.7214 30.7526 86.9898 30.7526Z" fill="#9F9F9F" />
      <path className="logo-btn__text" d="M97.8128 30.3797V16.9573H100.46V20.2259L100.137 19.8034C100.303 19.356 100.522 18.95 100.796 18.5854C101.077 18.2126 101.413 17.906 101.802 17.6657C102.134 17.442 102.498 17.268 102.896 17.1438C103.302 17.0112 103.716 16.9325 104.139 16.9076C104.561 16.8745 104.971 16.891 105.369 16.9573V19.7537C104.971 19.6377 104.512 19.6004 103.99 19.6418C103.476 19.6832 103.012 19.8282 102.598 20.0768C102.183 20.3005 101.844 20.5864 101.578 20.9343C101.322 21.2823 101.131 21.68 101.007 22.1274C100.883 22.5666 100.82 23.043 100.82 23.5567V30.3797H97.8128Z" fill="#9F9F9F" />
      <path className="logo-btn__text" d="M113.426 30.7526C112.084 30.7526 110.912 30.4502 109.909 29.8453C108.907 29.2405 108.128 28.4078 107.573 27.3473C107.026 26.2785 106.752 25.0522 106.752 23.6685C106.752 22.26 107.034 21.0255 107.597 19.9649C108.161 18.9044 108.944 18.0759 109.946 17.4793C110.949 16.8828 112.109 16.5845 113.426 16.5845C114.777 16.5845 115.953 16.8869 116.956 17.4917C117.958 18.0966 118.737 18.9334 119.292 20.0022C119.847 21.0628 120.125 22.2849 120.125 23.6685C120.125 25.0605 119.843 26.2909 119.28 27.3597C118.725 28.4202 117.946 29.2529 116.943 29.8578C115.941 30.4543 114.768 30.7526 113.426 30.7526ZM113.426 27.9438C114.619 27.9438 115.506 27.5461 116.086 26.7507C116.666 25.9553 116.956 24.9279 116.956 23.6685C116.956 22.3677 116.662 21.332 116.073 20.5615C115.485 19.7827 114.603 19.3933 113.426 19.3933C112.623 19.3933 111.96 19.5755 111.438 19.9401C110.924 20.2964 110.543 20.7976 110.294 21.4439C110.046 22.0819 109.921 22.8234 109.921 23.6685C109.921 24.9694 110.216 26.0092 110.804 26.788C111.4 27.5586 112.275 27.9438 113.426 27.9438Z" fill="#9F9F9F" />
      <path className="logo-btn__text" d="M127.897 30.765C126.902 30.765 126.082 30.5993 125.436 30.2679C124.79 29.9365 124.276 29.5139 123.895 29.0002C123.514 28.4865 123.232 27.9397 123.05 27.3597C122.867 26.7797 122.747 26.2329 122.689 25.7192C122.64 25.1972 122.615 24.7746 122.615 24.4515V16.9573H125.647V23.42C125.647 23.8342 125.676 24.3024 125.734 24.8244C125.792 25.3381 125.925 25.8352 126.132 26.3157C126.347 26.788 126.662 27.1774 127.076 27.484C127.499 27.7905 128.067 27.9438 128.779 27.9438C129.16 27.9438 129.537 27.8817 129.91 27.7574C130.283 27.6331 130.618 27.4218 130.917 27.1236C131.223 26.817 131.468 26.3986 131.65 25.8683C131.832 25.3381 131.923 24.6669 131.923 23.855L133.701 24.6131C133.701 25.7565 133.477 26.7921 133.029 27.7201C132.59 28.6481 131.94 29.3896 131.078 29.9448C130.217 30.4916 129.156 30.765 127.897 30.765ZM132.284 30.3797V26.2163H131.923V16.9573H134.931V30.3797H132.284Z" fill="#9F9F9F" />
      <path className="logo-btn__text" d="M144.873 30.7526C143.564 30.7526 142.466 30.4419 141.579 29.8205C140.693 29.1991 140.026 28.354 139.579 27.2851C139.131 26.208 138.907 25.0025 138.907 23.6685C138.907 22.318 139.131 21.1083 139.579 20.0395C140.026 18.9707 140.681 18.1297 141.542 17.5166C142.412 16.8952 143.485 16.5845 144.761 16.5845C146.029 16.5845 147.127 16.8952 148.055 17.5166C148.991 18.1297 149.716 18.9707 150.229 20.0395C150.743 21.1001 151 22.3097 151 23.6685C151 25.0108 150.747 26.2163 150.242 27.2851C149.736 28.354 149.024 29.1991 148.104 29.8205C147.185 30.4419 146.107 30.7526 144.873 30.7526ZM138.398 36.3453V16.9573H141.045V26.3779H141.418V36.3453H138.398ZM144.413 28.0681C145.192 28.0681 145.834 27.8734 146.339 27.484C146.845 27.0946 147.218 26.5684 147.458 25.9056C147.707 25.2345 147.831 24.4888 147.831 23.6685C147.831 22.8566 147.707 22.1192 147.458 21.4563C147.209 20.7852 146.824 20.2549 146.302 19.8655C145.78 19.4678 145.117 19.269 144.314 19.269C143.551 19.269 142.93 19.4554 142.449 19.8282C141.969 20.1928 141.613 20.7065 141.381 21.3693C141.157 22.0239 141.045 22.7903 141.045 23.6685C141.045 24.5385 141.157 25.3049 141.381 25.9677C141.613 26.6306 141.973 27.1484 142.462 27.5213C142.959 27.8858 143.609 28.0681 144.413 28.0681Z" fill="#9F9F9F" />
      <path className="logo-btn__symbol" d="M2.27482 10.6574L19.6255 0.584961L23.5245 2.85939L6.88867 12.477C5.39404 13.3218 4.48427 14.9464 4.48427 16.7009V31.5822L7.08362 33.0768V17.2858C7.08362 16.1161 7.66847 15.0763 8.70821 14.4915L26.1888 4.35402L36.6512 10.3975L19.9504 20.0801C18.4558 20.9249 17.546 22.5495 17.546 24.304V43.2143L13.582 40.8749V21.0548C13.582 19.8851 14.1668 18.8454 15.2066 18.2605L29.3081 10.1376L26.7087 8.64294L13.452 16.311C11.9574 17.1558 11.0476 18.7804 11.0476 20.535V39.4452L0.520264 33.3368V13.6467C0.520264 12.412 1.1701 11.2423 2.27482 10.6574Z" fill="#9F9F9F" />
      <path className="logo-btn__symbol" d="M18.0655 44.124L13.0617 41.1997V21.0547C13.0617 19.6901 13.7766 18.4554 14.9463 17.8055L28.2679 10.1375L26.7083 9.22769L13.7116 16.7658C12.4119 17.5456 11.5671 18.9753 11.5671 20.4699V40.2899L0 33.6616V13.6466C0 12.2169 0.779805 10.9173 2.0145 10.2024L19.6251 0L24.6288 2.85928L7.14821 12.9318C5.84854 13.7116 5.00375 15.1412 5.00375 16.6358V31.2572L6.56336 32.1669V17.2857C6.56336 15.921 7.27818 14.6863 8.44789 14.0365L26.1884 3.76906L37.6906 10.3974L20.2099 20.5349C18.9103 21.3147 18.0655 22.7443 18.0655 24.2389V44.124ZM14.1665 40.5499L17.0257 42.2394V24.3039C17.0257 22.4194 18.0655 20.5998 19.6901 19.6251L35.6111 10.3974L26.1884 5.00375L8.90277 14.9463C8.05798 15.4011 7.53811 16.3109 7.53811 17.2857V34.0515L3.89902 31.907V16.7008C3.89902 14.8163 4.93876 12.9967 6.56336 12.022L22.4844 2.85928L19.6251 1.23469L2.53437 11.1122C1.62459 11.6321 1.10472 12.6068 1.10472 13.6466V33.0117L10.5274 38.4704V20.4699C10.5274 18.5853 11.5671 16.7658 13.1917 15.791L26.7083 7.92802L30.3474 10.0725L15.4661 18.7153C14.6213 19.1702 14.1015 20.08 14.1015 21.0547L14.1665 40.5499Z" fill="#9F9F9F" />
      <path className="logo-btn__symbol" d="M19.495 44.189V24.8239C19.495 23.4592 20.2098 22.2245 21.3795 21.5097L38.3403 11.7621V17.6107L27.0331 24.174C25.5385 25.0188 24.6287 26.6434 24.6287 28.333V35.4812L32.2968 31.0623C33.1416 30.6074 33.5965 29.6976 33.5965 28.7879V22.8743L38.2103 20.21V31.3222C38.2103 32.6869 37.4955 33.9866 36.2608 34.6364L19.495 44.189ZM21.9644 22.4844C21.1196 22.9393 20.5997 23.8491 20.5997 24.8239V42.2395L35.8059 33.5967C36.6507 33.1418 37.1706 32.232 37.1706 31.2572V22.0945L34.8312 23.4592V28.7229C34.8312 30.0875 34.1163 31.3222 32.9466 32.0371L23.524 37.4307V28.268C23.524 26.1235 24.6937 24.174 26.5132 23.0693L37.3005 16.8309V13.5817L21.9644 22.4844Z" fill="#9F9F9F" />
    </svg>
  );
};

export default logoFooter;
