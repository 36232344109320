import { ObjectOf } from '../../../interfaces/interfaces';

import users from './users';
import headerBurger from './headerBurger';
import logo from './logo';
import logoFooter from './logoFooter';
import cabinetArrow from './cabinetArrow';
import multiLang from './multiLang';
import chevron from './chevron';
import telegram from './socials/telegram';
import facebook from './socials/facebook';
import youtube from './socials/youtube';
import instagram from './socials/instagram';
import historyArrow from './histotyArrow';
import aboutItem1 from './about/aboutItem1';
import aboutItem2 from './about/aboutItem2';
import aboutItem3 from './about/aboutItem3';
import aboutItem4 from './about/aboutItem4';
import broker1 from './brokers/broker1';
import broker2 from './brokers/broker2';
import broker3 from './brokers/broker3';
import broker4 from './brokers/broker4';
import logoSmall from './logoSmall';


const SVG_ICONS: ObjectOf<React.FC<any>> = {
  users,
  headerBurger,
  logo,
  logoFooter,
  cabinetArrow,
  multiLang,
  chevron,
  telegram,
  facebook,
  youtube,
  instagram,
  historyArrow,
  aboutItem1,
  aboutItem2,
  aboutItem3,
  aboutItem4,
  broker1,
  broker2,
  broker3,
  broker4,
  logoSmall,
}

export default SVG_ICONS;