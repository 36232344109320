import React from 'react';

const cabinetArrow = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 11 11" fill="none">
      <path
        d="M11 0.61111V8.55554C11 8.67641 10.9641 8.79456 10.897 8.89505C10.8298 8.99555 10.7344 9.07388 10.6227 9.12013C10.5111 9.16639 10.3882 9.17849 10.2697 9.15492C10.1511 9.13135 10.0422 9.07315 9.95674 8.98769L6.41667 5.44759L1.04324 10.821C0.928623 10.9356 0.773178 11 0.611098 11C0.449018 11 0.293578 10.9356 0.178975 10.821C0.0643722 10.7064 -7.01901e-06 10.5509 5.73974e-10 10.3888C7.02016e-06 10.2268 0.0643999 10.0713 0.179013 9.95673L5.55241 4.58333L2.01231 1.04326C1.92684 0.957788 1.86864 0.848895 1.84507 0.730348C1.82149 0.611802 1.8336 0.488926 1.87985 0.377259C1.92611 0.265592 2.00444 0.170148 2.10494 0.102997C2.20543 0.0358456 2.32359 2.50617e-06 2.44446 0H10.3889C10.551 7.90054e-06 10.7064 0.0643952 10.821 0.178999C10.9356 0.293603 11 0.449036 11 0.61111V0.61111Z"
        fill="white" />
    </svg>
  );
};

export default cabinetArrow;
