import React from 'react';

const aboutItem4 = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M54.4286 31.9987C54.4286 41.0195 47.1159 48.3322 38.0952 48.3322C29.0745 48.3322 21.7617 41.0195 21.7617 31.9987C21.7617 22.978 29.0745 15.6653 38.0952 15.6653C47.1159 15.6653 54.4286 22.978 54.4286 31.9987Z" fill="url(#paint0_linear_395_325)" />
      <path d="M38.5 25.5C38.5 35.1285 30.4781 43 20.5 43C10.5219 43 2.5 35.1285 2.5 25.5C2.5 15.8715 10.5219 8 20.5 8C30.4781 8 38.5 15.8715 38.5 25.5Z" stroke="#0346F1" strokeWidth="3" />
      <defs>
        <linearGradient id="paint0_linear_395_325" x1="18.0525" y1="5.31003" x2="51.9348" y2="66.5682" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default aboutItem4;
