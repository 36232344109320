export default {
  documents: 'Документы',
  termsOfUse: 'Условия использования',
  privacyPolicy: 'Политика конфиденциальности',
  AMLPolicy: 'Политика AML',
  returnPolicy: 'Политика возврата',
  risks: 'Риски',
  aboutCompany: 'О компании',
  mediaCenter: 'Медиацентр',
  cabinet: 'Кабинет',
  socialNetworks: 'Соцсети',
  aboutUs: 'О нас',
  whyWe: 'Почему мы',
  profit: 'Доходность',
  brokers: 'Брокеры',
  conditions: 'Условия',
  address: 'Компания SINCERE SYSTEMS GROUP LTD, зарегистрирована на 71-75 Shelton Street, London, Greater London, United Kingdom, WC2H 9JQ, регистрационный номер 12695421',

  //hero
  algorithmicTrading: 'Алгоритмический трейдинг на валютном рынке',
  successTradeHistory: 'Успешная торговая история с гибкими условиями распределения дохода в международном публичном пуле <b>S-Forex</b>',
  createAccount: 'Создать аккаунт',
  historyTransactions: 'История сделок',
  label1: 'Прибыльной<br/> торговли подряд',
  label2: 'Средняя<br/> доходность',
  label3: 'Простой<br/> процент',
  label4: 'Сложный<br/> процент',
  monthsArr: ['месяц', 'месяца', 'месяцев'],

  //about
  whatIsSForex: 'Что такое S-Forex',
  sForexIs: '<b>S-Forex</b> — это международное инвестиционное направление, публичный пул которого был успешно запущен в 2019 году Британским инвестиционным фондом <b><u>S-Group</u></b>',
  autoTradeRobot: 'Автоматизированный торговый робот на основе искусственного интеллекта, уникальность которого заключается в одновременной поддержке множества торговых стратегий и использовании передовых методов управления рисками',

  //why
  whyForex: 'Почему S-Forex?',
  weCareOfQuality: 'Мы заботимся о нашем качестве и&nbsp;доступности инвестиции для каждого',
  reason1: 'Открытая статистика',
  reason1info: 'Общедоступный мониторинг закрытых сделок в&nbsp;личном кабинете инвестора',
  reason2: 'Доступность',
  reason2info: 'Постоянный доступ к личному счету, быстрый&nbsp;ввод и&nbsp;вывод средств',
  reason3: 'Полная отчетность',
  reason3info: 'Доступ ко всем торговым операциям в&nbsp;PDF формате за&nbsp;время работы публичного пула',
  reason4: 'Лояльность',
  reason4info: 'Минимальная инвестиция $100, а средства поступают в работу уже на следующий день',

  //brokers
  brokerInfo1: 'Один из самых крупных брокеров, работающий на территории СНГ с 2007 года',
  brokerInfo2: 'Швейцарская банковская группа, специализирующаяся на предоставлении онлайн финансовых и торговых услуг',
  brokerInfo3: 'Один из крупнейших форекс-брокеров, совершивший прорыв на рынке финансов за счет своих технологических решений',
  brokerInfo4: 'Международный брокер, предлагающий к торговле 8 видов активов и более чем 12,000 инструментов',
  ourBrokers: 'Брокеры S-Forex',
  tradingCarried: 'Торговля осуществляется на счетах у разных брокеров для диверсификации возможных рисков',

  //conditions
  conditionsDistributionIncome: 'Условия распределения дохода',
  yourShareIncome: 'Ваша часть <br/>дохода',
  companyIncome: 'Доход <br/>компании',
  minInvest: 'Минимальная <br/>инвестиция',
  from: 'от',

  //oppotunity
  openAccount: 'Открыть счет',
  discoverPossibilities: 'Откройте новые возможности',
  reliableSourcePassiveIncome: 'Надежный источник пассивного дохода с результативностью в 100% успешных торговых дней за всё время',

  //statistic
  statisticProfit: 'Статистика доходности',
  indicatorsAvailable: 'Показатели доступны с самого первого дня открытия публичного пула S-Forex',

  //cookie
  cookieText: 'Продолжая просматривать сайт, Вы&nbsp;принимаете использование cookies и соглашаетесь с Политикой конфиденциальности <i>S-Group</i> и&nbsp;Условиями использования <i>S-Group</i>.',
  accept: 'Принять',

  notAvailable: 'Foundation services are not available for your region',
}