import React, {createContext, useCallback, useState} from 'react';
import { AnchorTypes, IAnchorsDataProps, ICheckActiveSectionContextProps } from "./CheckActiveSections.types";

const initAnchors = {
  about: {top: 0, bottom: 0,},
  conditions: {top: 0, bottom: 0,},
  why: {top: 0, bottom: 0,},
  brokers: {top: 0, bottom: 0,},
  statistic: {top: 0, bottom: 0,},
}

const CheckActiveSectionContext = createContext<ICheckActiveSectionContextProps>({
  sectionsParams: initAnchors,
  handleActiveSection: () => {},
});

const CheckActiveSectionProvider = (props: any) => {
  const {
    children,
  } = props

  const [sectionsParams, setSectionsParams] = useState<IAnchorsDataProps>(initAnchors);

  const handleActiveSection = useCallback((sectionName: AnchorTypes, top: number | undefined, bottom: number | undefined) => {
    setSectionsParams(prevState => ({
      ...prevState,
      [sectionName]: {
        top, bottom
      }
    }))
  }, [])

  return (
    <CheckActiveSectionContext.Provider value={{sectionsParams, handleActiveSection}}>
      {children}
    </CheckActiveSectionContext.Provider>
  )
}

export { CheckActiveSectionContext, CheckActiveSectionProvider }