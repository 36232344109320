import React from 'react';

const historyArrow = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 13 13" fill="none">
      <path d="M12 1L1 12M12 1H2.72222M12 1V10.2778" stroke="white" />
    </svg>
  );
};

export default historyArrow;
