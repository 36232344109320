import axios from "axios";

type Env = 'PROD' | 'DEV';

const host = window.location.hostname;

export const ENV: Env = (() => {
  return host === 's-forex.io'
    ? 'PROD'
    : 'DEV';
})();

const baseURL = {
  PROD: {
    api: 'https://mediacenter.s-group.io/api/forex/',
  },
  DEV: {
    api: 'https://sg-back-dev.ourstage.app/api/forex/',
  },
}

const apiBaseUrl = baseURL[ENV].api;

axios.defaults.baseURL = apiBaseUrl;