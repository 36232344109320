import React, { useContext } from 'react';

import { MultiLangContext } from '../../context/MultiLang/MultiLang';
import { useCheckWindowSize } from '../../utils';

import SvgIcon from '../../components/SvgIcon/SvgIcon';
import './NotAvailable.scss';


const NotAvailable = () => {
  const { words } = useContext(MultiLangContext);

  const isDesktop = useCheckWindowSize('IS_GREATER_THAN', 992);

  return (
    <div className='notAvailable__wrapper'>
      <div className='container notAvailable__container'>
        <div className='notAvailable__text'>
          <h2 className='notAvailable__title'>
            <SvgIcon icon='logoSmall' size={isDesktop ? [41, 48] : [32, 38]} />
            S-Forex
          </h2>

          <p className='notAvailable__subtitle'>
            {words.general.notAvailable}
          </p>
        </div>

        <div className='notAvailable__image'>
          <img src='/media/images/notAvailable.webp' alt='not available' width={650} height={584} />
        </div>
      </div>
    </div>
  );
};

export default NotAvailable;
