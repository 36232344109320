import React from 'react';

const broker3 = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 224 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.2017 46.9979C29.4181 47.6487 31.767 48.0001 34.2005 48.0001C38.9741 48.0001 43.4193 46.6532 47.1682 44.3383L27.2017 20.332V46.9979Z" fill="#0346F1" />
      <mask id="mask0_339_192" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="21" y="1" width="38" height="37">
        <path d="M21.1256 1H58.4007V37.4393H21.1256V1Z" fill="white" />
      </mask>
      <g mask="url(#mask0_339_192)">
        <path fillRule="evenodd" clipRule="evenodd" d="M27.2012 4.74217L54.3996 37.4395C56.9259 33.7287 58.4007 29.281 58.4007 24.5005C58.4007 11.5216 47.5658 1 34.2 1C29.3823 1 24.897 2.37329 21.1256 4.73011H27.2012V4.74217Z" fill="#0346F1" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99994 24.4982C9.99994 30.8949 12.6361 36.693 16.9063 40.9307V8.06641C12.6361 12.3046 9.99994 18.1018 9.99994 24.4982Z" fill="#0346F1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M84.1245 37.5316C76.4745 37.5316 71.029 31.6967 71.029 24.5004C71.029 17.3031 76.4093 11.4696 84.1245 11.4696C91.7732 11.4696 97.2828 17.3031 97.2828 24.5004C97.2828 31.6967 91.9026 37.5316 84.1245 37.5316ZM84.1176 8.85986C74.8591 8.85986 68.4008 15.8623 68.4008 24.5003C68.4008 33.1383 74.9363 40.1398 84.1176 40.1398C93.4539 40.1398 99.9121 33.1383 99.9121 24.5003C99.9121 15.8623 93.2978 8.85986 84.1176 8.85986Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M167.02 39.6522H157.145V9.56738H167.02C176.349 9.77852 182.561 16.7552 182.561 25.1078C182.561 32.9069 176.286 39.6522 167.02 39.6522ZM160.096 12.1642V37.0461H166.855C175.26 37.0461 179.783 30.8594 179.783 24.9402C179.783 18.6321 175.657 12.1642 166.855 12.1642H160.096Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M188.681 30.6008V13.9356H188.884L202.729 30.6008H188.681ZM210.248 39.6512H213.777L188.751 9.56885H185.731V39.6512H188.681V33.0436H204.759L210.248 39.6512Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M128.481 9.59912H131.778L149.827 35.0465H149.914V9.59912H152.864V39.6821H149.566L131.518 14.235H131.431V39.6821H128.481V9.59912Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M105.731 30.63L112.934 12.7371L120.08 30.63H105.731ZM123.694 39.6815H126.86L114.713 9.59912H111.415L98.9204 39.6815H102.088L104.747 33.0739H121.055L123.694 39.6815Z" fill="white" />
    </svg>
  );
};

export default broker3;
