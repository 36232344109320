import {
  ILanguage,
} from "../interfaces/interfaces";
import {keyframes} from "@emotion/react";

export const LANGUAGES: ILanguage[] = [
  { value: 'en', name: 'Eng' },
  { value: 'ru', name: 'Ru' },
]

export const LINKS = {
  signUp: 'https://backoffice.s-group.io/sign_up',
  signIn: 'https://backoffice.s-group.io/sign_in',

  termsOfUse: '/docs/terms-of-use.pdf',
  privacyPolicy: '/docs/privacy-policy.pdf',
  amlPolicy: '/docs/aml-policy.pdf',
  kyc: 'https://s-group.io/docs/kyc.pdf',
  refundPolicy: 'https://s-forex.io/documents/refund-policy.pdf',
  risks: 'https://s-forex.io/documents/risks.pdf',
  mediaCenter: 'https://s-group.io/media-center',
  tradingReport: 'https://backoffice.s-group.io/assets/documents/sin-trading-report.pdf',

  sGroup: 'https://s-group.io/',
}

export const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const BLOCKED_COUNTRIES = ['US', 'CN', 'SG', 'KP', 'CU', 'IR', 'SD', 'SY'];
