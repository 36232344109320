import React from 'react';

const multiLang = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 14.9706 15.9706 19 11 19M20 10C20 5.02944 15.9706 1 11 1M20 10H2M11 19C6.02944 19 2 14.9706 2 10M11 19C13.9908 19 16.4153 14.9706 16.4153 10C16.4153 5.02944 13.9908 1 11 1M11 19C8.00924 19 5.58475 14.9706 5.58475 10C5.58475 5.02944 8.00924 1 11 1M11 19C12.011 19 12.8305 14.9706 12.8305 10C12.8305 5.02944 12.011 1 11 1M11 19C9.98904 19 9.16949 14.9706 9.16949 10C9.16949 5.02944 9.98904 1 11 1M2 10C2 5.02944 6.02944 1 11 1M4.28814 4.05085H17.75M4.25 15.9492H17.75" stroke="white" strokeWidth="0.5" />
    </svg>

  );
};

export default multiLang;
