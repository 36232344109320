import React from 'react';

const chevron = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 11 11" fill="none">
      <path d="M9 4.39111L5.51894 7.98029L1.92976 4.49923" stroke="white" />
    </svg>
  );
};

export default chevron;
