import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { MultiLangProvider } from "./context/MultiLang/MultiLang";
import { ModalProvider } from "./context/ModalContext/ModalContext";
import { CheckActiveSectionProvider } from "./context/CheckActiveSection/CheckActiveSection";
import { getClearedPathname, hasPathnameExtraSlashes } from './utils';

import Main from "./pages/Main";


const App = () => {
  const location = useLocation();

  return (
    <CheckActiveSectionProvider>
      <ModalProvider>
        <MultiLangProvider>
          {hasPathnameExtraSlashes(location.pathname)
            ? <Redirect to={getClearedPathname(location.pathname)} />
            : <Switch>
              <Route exact path="/" component={Main} />
              <Redirect to="/" />
            </Switch>
          }
        </MultiLangProvider>
      </ModalProvider>
    </CheckActiveSectionProvider>
  );
}

export default App;
