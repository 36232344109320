import React from 'react';

const headerBurger = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 15 13" fill="none">
      <circle className="header__svg-element header__svg-element--1" cx="1.5" cy="1.5" r="1.5"
        fill="white" />
      <circle className="header__svg-element header__svg-element--2" cx="7.5" cy="6.5" r="1.5"
        fill="white" />
      <circle className="header__svg-element header__svg-element--3" cx="13.5" cy="1.5" r="1.5"
        fill="white" />
      <circle className="header__svg-element header__svg-element--4" cx="1.5" cy="11.5" r="1.5"
        fill="white" />
      <circle className="header__svg-element header__svg-element--5" cx="13.5" cy="11.5" r="1.5"
        fill="white" />
    </svg>
  );
};

export default headerBurger;
