import React from 'react';

const telegram = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM12.9478 9.22796C11.732 9.73366 9.30206 10.7803 5.65806 12.368C5.06633 12.6033 4.75635 12.8335 4.72814 13.0586C4.68045 13.439 5.15683 13.5888 5.80554 13.7927C5.89378 13.8205 5.98521 13.8492 6.07894 13.8797C6.71717 14.0872 7.5757 14.3299 8.02201 14.3395C8.42686 14.3483 8.87872 14.1814 9.37759 13.8388C12.7823 11.5405 14.5398 10.3789 14.6502 10.3538C14.728 10.3362 14.8359 10.314 14.909 10.3789C14.9821 10.4439 14.9749 10.5669 14.9671 10.5999C14.9199 10.8011 13.05 12.5396 12.0823 13.4393C11.7806 13.7197 11.5666 13.9187 11.5228 13.9641C11.4248 14.0659 11.325 14.1622 11.229 14.2547C10.6361 14.8263 10.1914 15.2549 11.2536 15.9549C11.764 16.2913 12.1725 16.5694 12.58 16.8469C13.025 17.15 13.4689 17.4523 14.0432 17.8287C14.1895 17.9246 14.3292 18.0243 14.4653 18.1213C14.9832 18.4905 15.4485 18.8222 16.0234 18.7693C16.3574 18.7386 16.7024 18.4245 16.8776 17.4878C17.2917 15.274 18.1057 10.4775 18.2938 8.50093C18.3103 8.32775 18.2896 8.10613 18.2729 8.00884C18.2563 7.91155 18.2215 7.77294 18.095 7.67033C17.9452 7.5488 17.714 7.52318 17.6106 7.525C17.1405 7.53328 16.4192 7.78409 12.9478 9.22796Z"
        fill="#9F9F9F" />
    </svg>
  );
};

export default telegram;
