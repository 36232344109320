import React from 'react';

const aboutItem2 = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="18.3316" cy="17.1861" rx="9.73958" ry="9.73958" fill="url(#paint0_linear_52_51)" />
      <ellipse cx="38.8136" cy="16.7564" rx="9.73958" ry="9.73958" fill="#0346F1" />
      <ellipse cx="17.7589" cy="37.8111" rx="9.73958" ry="9.73958" fill="#0346F1" />
      <ellipse cx="38.8136" cy="37.8111" rx="9.73958" ry="9.73958" fill="url(#paint1_linear_52_51)" />
      <defs>
        <linearGradient id="paint0_linear_52_51" x1="6.38022" y1="1.27173" x2="26.5841" y2="37.7997" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_52_51" x1="26.8622" y1="21.8967" x2="47.0661" y2="58.4247" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default aboutItem2;
