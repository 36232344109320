import React, { useState, useEffect } from 'react';

import './GlobalPreloader.scss';


interface IGlobalPreloaderProps {
  setLoadPending: React.SetStateAction<any>
}


const GlobalPreloader = (props: IGlobalPreloaderProps) => {
  const {
    setLoadPending,
  } = props

  const [loadingStatus, setLoadingStatus] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);

      setTimeout(() => {
        setLoadPending(false)
      }, 500)
    }, 1500)

    const counter = setTimeout(() => {
      setLoadingStatus(prevState => prevState + 10);
    }, 50);

    loadingStatus === 100 && clearTimeout(counter);

    // eslint-disable-next-line
  }, [loadingStatus])


  return (
    <div
      className="main-overlay"
      data-is-loaded={isLoaded}>

      <div className="preloader">
        <div className="preloader__bg-circle" />
        <div className="preloader__box">
          <div className="preloader__dot preloader__dot-1" />
          <div className="preloader__dot preloader__dot-2" />
          <div className="preloader__dot preloader__dot-3" />
        </div>

        <div className="preloader__perc">
          <span className="preloader__perc_num">{loadingStatus}</span>%
        </div>

        <svg style={{ position: "absolute", opacity: 0, visibility: "hidden" }}>
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  )
};

export default GlobalPreloader;