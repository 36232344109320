export default {
  documents: 'Documentation',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  AMLPolicy: 'AML policy',
  returnPolicy: 'Refund policy',
  risks: 'Risks',
  aboutCompany: 'About company',
  mediaCenter: 'Media center',
  cabinet: 'Cabinet',
  socialNetworks: 'Social network',
  aboutUs: 'About us',
  whyWe: 'Why us',
  profit: 'Profitability',
  brokers: 'Brokers',
  conditions: 'Conditions',
  address: 'SINCERE SYSTEMS GROUP LTD, registered at 71-75 Shelton Street, London, Greater London, United Kingdom, WC2H 9JQ, registration number 12695421',

  //hero
  algorithmicTrading: 'Algorithmic trading in the foreign exchange market',
  successTradeHistory: 'Successful trading history with flexible income distribution terms in an international public pool <b>S-Forex</b>',
  createAccount: 'Create an account',
  historyTransactions: 'History of transactions',
  label1: 'Profitable<br/> trading in a row',
  label2: 'Average<br/> yield',
  label3: 'Simple<br/> interest',
  label4: 'Compound<br/> interest',
  monthsArr: ['month', 'months', 'months'],

  //about
  whatIsSForex: 'What is S-Forex',
  sForexIs: '<b>S-Forex</b> is an international investment area, the public pool of which was successfully launched in 2019 by the British Investment Fund <b><u>S-Group</u></b>',
  autoTradeRobot: 'An automated trading robot based on artificial intelligence, the uniqueness of which lies in the simultaneous support of multiple trading strategies and the use of advanced risk management methods',

  //why
  whyForex: 'Why S-Forex?',
  weCareOfQuality: 'We care about our quality and availability of investment for everyone',
  reason1: 'Open stasistics',
  reason1info: 'Public monitoring of closed transactions in the investor\'s personal account',
  reason2: 'Availability',
  reason2info: 'Permanent access to your personal account, fast deposit and withdrawal of funds',
  reason3: 'Complete reporting',
  reason3info: 'Access to all trading operations in PDF format during the operation of the public pool',
  reason4: 'Loyalty',
  reason4info: 'The minimum investment is $ 100, and the funds go into operation the very next day',

  //brokers
  brokerInfo1: 'One of the largest brokers operating in the CIS since 2007',
  brokerInfo2: 'Swiss banking group specializing in the provision of online financial and trading services',
  brokerInfo3: 'One of the largest forex brokers that has made a breakthrough in the financial market due to its technological solutions',
  brokerInfo4: 'International broker offering for trading 8 types of assets and more than 12,000 instruments',
  ourBrokers: 'Brokers S-Forex',
  tradingCarried: 'Trading is carried out on accounts with different brokers to diversify possible risks',

  //conditions
  conditionsDistributionIncome: 'Conditions for the distribution of income',
  yourShareIncome: 'Your share <br/>of income',
  companyIncome: 'Company <br/>income',
  minInvest: 'Minimum <br/>investment',
  from: 'from',

  //oppotunity
  openAccount: 'Open an account',
  discoverPossibilities: 'Discover new possibilities',
  reliableSourcePassiveIncome: 'Reliable source of passive income with 100% success rate of trading days all the time',

  //statistic
  statisticProfit: 'Profitability statistics',
  indicatorsAvailable: 'The indicators are available from the very first day of the opening of the S-Forex public pool',

  //cookie
  cookieText: 'By continuing to browse the site, you&nbsp;accept the use of cookies and agree to the <i>S-Group</i> Privacy Policy and the <i>S-Group</i> Terms of Use.',
  accept: 'Accept',

  notAvailable: 'Foundation services are not available for your region',
}