import React, { useState, lazy, Suspense, useContext, useEffect } from 'react';

import '../config/api-config';

import { MultiLangContext } from '../context/MultiLang/MultiLang';
import { BLOCKED_COUNTRIES } from '../config/constants';

import GlobalPreloader from "../components/GlobalPreloader/GlobalPreloader";
import NotAvailable from '../containers/NotAvailable/NotAvailable';

const Header = lazy(() => import("../containers/Header/Header"));
const Hero = lazy(() => import("../containers/Hero/Hero"));
const About = lazy(() => import("../containers/About/About"));
const Why = lazy(() => import("../containers/Why/Why"));
const Statistic = lazy(() => import("../containers/Statistic/Statistic"));
// const Brokers = lazy(() => import("../containers/Brokers/Brokers"));
const Conditions = lazy(() => import("../containers/Conditions/Conditions"));
const Opportunity = lazy(() => import("../containers/Opportunity/Opportunity"));
const Footer = lazy(() => import("../containers/Footer/Footer"));
const Cookie = lazy(() => import("../containers/Cookie/Cookie"));


const Main = () => {
  const [loadPending, setLoadPending] = useState(true);
  const [state, setState] = useState({
    isBlocked: true,
    isFinished: false,
  });

  const {
    selectedLang
  } = useContext(MultiLangContext);

  useEffect(() => {
    const helpCrunchLang = document.createElement('script');
    helpCrunchLang.type = 'text/javascript';
    helpCrunchLang.innerHTML = `HelpCrunch('setPhraseList', '${selectedLang}');`;
    document.body.appendChild(helpCrunchLang);

    return () => {
      document.body.removeChild(helpCrunchLang);
    }
  }, [selectedLang]);

  useEffect(() => {
    fetch(`https://json.geoiplookup.io/`, { cache: 'reload' })
      .then(res => res.json())
      .then(data => {
        if (!BLOCKED_COUNTRIES.includes(data.country_code)) {
          setState(prev => ({
            ...prev,
            isBlocked: false,
          }));
        }
      })
      .catch(() => setState(prev => ({
        ...prev,
        isBlocked: false,
      })))
      .finally(() => setState(prev => ({
        ...prev,
        isFinished: true,
      })));
  }, []);

  if (loadPending && state.isFinished) return <GlobalPreloader setLoadPending={setLoadPending} />

  if (state.isBlocked && state.isFinished) return <NotAvailable />

  return (
    <Suspense fallback={''}>
      {!loadPending && <Cookie />}

      <Header />

      <Hero />
      <About isLoaded={!loadPending} />
      <Why isLoaded={!loadPending} />
      <Statistic isLoaded={!loadPending} />
      {/* <Brokers isLoaded={loadPending} /> */}
      <Conditions isLoaded={!loadPending} />
      <Opportunity />
      <Footer />
    </Suspense>
  )
}

export default Main;