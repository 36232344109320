import React from 'react';

const users = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 55 55" fill="none">
      <mask id="path-2-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3149 24.1898C22.0963 24.1898 25.9723 20.3138 25.9723 15.5324C25.9723 10.7511 22.0963 6.875 17.3149 6.875C12.5336 6.875 8.6575 10.7511 8.6575 15.5324C8.6575 20.3138 12.5336 24.1898 17.3149 24.1898ZM34.6296 39.3402C34.6296 44.6878 25.0103 45.8333 17.3148 45.8333C9.61934 45.8333 0 44.6878 0 39.3402C0 33.9927 7.75211 26.3541 17.3148 26.3541C26.8775 26.3541 34.6296 33.9927 34.6296 39.3402Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.3149 24.1898C22.0963 24.1898 25.9723 20.3138 25.9723 15.5324C25.9723 10.7511 22.0963 6.875 17.3149 6.875C12.5336 6.875 8.6575 10.7511 8.6575 15.5324C8.6575 20.3138 12.5336 24.1898 17.3149 24.1898ZM34.6296 39.3402C34.6296 44.6878 25.0103 45.8333 17.3148 45.8333C9.61934 45.8333 0 44.6878 0 39.3402C0 33.9927 7.75211 26.3541 17.3148 26.3541C26.8775 26.3541 34.6296 33.9927 34.6296 39.3402Z" fill="#111E35"/>
      <path d="M24.9723 15.5324C24.9723 19.7615 21.544 23.1898 17.3149 23.1898V25.1898C22.6486 25.1898 26.9723 20.866 26.9723 15.5324H24.9723ZM17.3149 7.875C21.544 7.875 24.9723 11.3033 24.9723 15.5324H26.9723C26.9723 10.1988 22.6486 5.875 17.3149 5.875V7.875ZM9.6575 15.5324C9.6575 11.3033 13.0858 7.875 17.3149 7.875V5.875C11.9813 5.875 7.6575 10.1988 7.6575 15.5324H9.6575ZM17.3149 23.1898C13.0858 23.1898 9.6575 19.7615 9.6575 15.5324H7.6575C7.6575 20.866 11.9813 25.1898 17.3149 25.1898V23.1898ZM17.3148 46.8333C21.1894 46.8333 25.629 46.5473 29.1309 45.5541C30.8793 45.0583 32.4746 44.3645 33.6499 43.3761C34.8542 42.3635 35.6296 41.0251 35.6296 39.3402H33.6296C33.6296 40.3291 33.2026 41.1392 32.3628 41.8454C31.4941 42.5759 30.2036 43.1711 28.5852 43.63C25.3535 44.5465 21.1357 44.8333 17.3148 44.8333V46.8333ZM-1 39.3402C-1 41.0251 -0.224586 42.3635 0.979692 43.3761C2.15506 44.3645 3.75038 45.0583 5.49876 45.5541C9.00066 46.5473 13.4403 46.8333 17.3148 46.8333V44.8333C13.4939 44.8333 9.27609 44.5465 6.04445 43.63C4.42606 43.1711 3.13558 42.5759 2.26684 41.8454C1.427 41.1392 1 40.3291 1 39.3402H-1ZM17.3148 25.3541C12.2686 25.3541 7.72106 27.3667 4.43603 30.0696C1.19328 32.7377 -1 36.2394 -1 39.3402H1C1 37.0934 2.68278 34.1021 5.70676 31.614C8.68845 29.1607 12.7983 27.3541 17.3148 27.3541V25.3541ZM35.6296 39.3402C35.6296 36.2394 33.4364 32.7377 30.1936 30.0696C26.9086 27.3667 22.361 25.3541 17.3148 25.3541V27.3541C21.8313 27.3541 25.9412 29.1607 28.9229 31.614C31.9469 34.1021 33.6296 37.0934 33.6296 39.3402H35.6296Z" fill="url(#paint0_linear)" mask="url(#path-2-inside-1)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.5453 45.2905C22.8921 45.6949 19.9875 45.8331 17.3149 45.8331C14.6423 45.8331 11.7376 45.6949 9.08447 45.2905C10.578 48.327 13.7024 50.4167 17.3149 50.4167C20.9274 50.4167 24.0518 48.327 25.5453 45.2905Z" fill="url(#paint1_linear)"/>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="6" width="35" height="40">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3149 24.1898C22.0963 24.1898 25.9723 20.3138 25.9723 15.5324C25.9723 10.7511 22.0963 6.875 17.3149 6.875C12.5336 6.875 8.6575 10.7511 8.6575 15.5324C8.6575 20.3138 12.5336 24.1898 17.3149 24.1898ZM34.6296 39.3402C34.6296 44.6878 25.0103 45.8333 17.3148 45.8333C9.61934 45.8333 0 44.6878 0 39.3402C0 33.9927 7.75211 26.3541 17.3148 26.3541C26.8775 26.3541 34.6296 33.9927 34.6296 39.3402Z" fill="white"/>
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_f)">
          <ellipse cx="17.3149" cy="41.2502" rx="9.16667" ry="9.16666" fill="url(#paint2_linear)"/>
        </g>
      </g>
      <mask id="path-7-inside-2" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3149 24.1898C22.0963 24.1898 25.9723 20.3138 25.9723 15.5324C25.9723 10.7511 22.0963 6.875 17.3149 6.875C12.5336 6.875 8.6575 10.7511 8.6575 15.5324C8.6575 20.3138 12.5336 24.1898 17.3149 24.1898ZM34.6296 39.3402C34.6296 44.6878 25.0103 45.8333 17.3148 45.8333C9.61934 45.8333 0 44.6878 0 39.3402C0 33.9927 7.75211 26.3541 17.3148 26.3541C26.8775 26.3541 34.6296 33.9927 34.6296 39.3402Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.3149 24.1898C22.0963 24.1898 25.9723 20.3138 25.9723 15.5324C25.9723 10.7511 22.0963 6.875 17.3149 6.875C12.5336 6.875 8.6575 10.7511 8.6575 15.5324C8.6575 20.3138 12.5336 24.1898 17.3149 24.1898ZM34.6296 39.3402C34.6296 44.6878 25.0103 45.8333 17.3148 45.8333C9.61934 45.8333 0 44.6878 0 39.3402C0 33.9927 7.75211 26.3541 17.3148 26.3541C26.8775 26.3541 34.6296 33.9927 34.6296 39.3402Z" fill="url(#paint3_linear)" fill-opacity="0.2"/>
      <path d="M24.9723 15.5324C24.9723 19.7615 21.544 23.1898 17.3149 23.1898V25.1898C22.6486 25.1898 26.9723 20.866 26.9723 15.5324H24.9723ZM17.3149 7.875C21.544 7.875 24.9723 11.3033 24.9723 15.5324H26.9723C26.9723 10.1988 22.6486 5.875 17.3149 5.875V7.875ZM9.6575 15.5324C9.6575 11.3033 13.0858 7.875 17.3149 7.875V5.875C11.9813 5.875 7.6575 10.1988 7.6575 15.5324H9.6575ZM17.3149 23.1898C13.0858 23.1898 9.6575 19.7615 9.6575 15.5324H7.6575C7.6575 20.866 11.9813 25.1898 17.3149 25.1898V23.1898ZM17.3148 46.8333C21.1894 46.8333 25.629 46.5473 29.1309 45.5541C30.8793 45.0583 32.4746 44.3645 33.6499 43.3761C34.8542 42.3635 35.6296 41.0251 35.6296 39.3402H33.6296C33.6296 40.3291 33.2026 41.1392 32.3628 41.8454C31.4941 42.5759 30.2036 43.1711 28.5852 43.63C25.3535 44.5465 21.1357 44.8333 17.3148 44.8333V46.8333ZM-1 39.3402C-1 41.0251 -0.224586 42.3635 0.979692 43.3761C2.15506 44.3645 3.75038 45.0583 5.49876 45.5541C9.00066 46.5473 13.4403 46.8333 17.3148 46.8333V44.8333C13.4939 44.8333 9.27609 44.5465 6.04445 43.63C4.42606 43.1711 3.13558 42.5759 2.26684 41.8454C1.427 41.1392 1 40.3291 1 39.3402H-1ZM17.3148 25.3541C12.2686 25.3541 7.72106 27.3667 4.43603 30.0696C1.19328 32.7377 -1 36.2394 -1 39.3402H1C1 37.0934 2.68278 34.1021 5.70676 31.614C8.68845 29.1607 12.7983 27.3541 17.3148 27.3541V25.3541ZM35.6296 39.3402C35.6296 36.2394 33.4364 32.7377 30.1936 30.0696C26.9086 27.3667 22.361 25.3541 17.3148 25.3541V27.3541C21.8313 27.3541 25.9412 29.1607 28.9229 31.614C31.9469 34.1021 33.6296 37.0934 33.6296 39.3402H35.6296Z" fill="url(#paint4_linear)" mask="url(#path-7-inside-2)"/>
      <defs>
        <filter id="filter0_f" x="-1.85181" y="22.0835" width="38.3333" height="38.3333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur"/>
        </filter>
        <linearGradient id="paint0_linear" x1="-0.692593" y1="6.875" x2="38.6794" y2="41.1862" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.18"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="9.08447" y1="47.7187" x2="13.6923" y2="55.094" gradientUnits="userSpaceOnUse">
          <stop stopColor="#26D4CA"/>
          <stop offset="1" stopColor="#BAF2B5"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="8.14819" y1="40.7677" x2="22.79" y2="48.066" gradientUnits="userSpaceOnUse">
          <stop stopColor="#26D4CA"/>
          <stop offset="1" stopColor="#BAF2B5"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="0" y1="6.875" x2="37.7224" y2="40.406" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BAF2B5"/>
          <stop offset="1" stopColor="#26AAD4"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="-0.692593" y1="6.875" x2="38.6794" y2="41.1862" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.18"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default users;