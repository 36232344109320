import React from 'react';

const youtube = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.751 7.5C24.751 7.5 24.5068 5.77637 23.7549 5.01953C22.8027 4.02344 21.7383 4.01855 21.25 3.95996C17.7539 3.70605 12.5049 3.70605 12.5049 3.70605H12.4951C12.4951 3.70605 7.24609 3.70605 3.75 3.95996C3.26172 4.01855 2.19727 4.02344 1.24512 5.01953C0.493164 5.77637 0.253906 7.5 0.253906 7.5C0.253906 7.5 0 9.52637 0 11.5479V13.4424C0 15.4639 0.249023 17.4902 0.249023 17.4902C0.249023 17.4902 0.493164 19.2139 1.24023 19.9707C2.19238 20.9668 3.44238 20.9326 3.99902 21.04C6.00098 21.2305 12.5 21.2891 12.5 21.2891C12.5 21.2891 17.7539 21.2793 21.25 21.0303C21.7383 20.9717 22.8027 20.9668 23.7549 19.9707C24.5068 19.2139 24.751 17.4902 24.751 17.4902C24.751 17.4902 25 15.4687 25 13.4424V11.5479C25 9.52637 24.751 7.5 24.751 7.5ZM9.91699 15.7422V8.71582L16.6699 12.2412L9.91699 15.7422Z"
        fill="#9F9F9F" />
    </svg>
  );
};

export default youtube;
